:root {
    background-color: transparent;
}

.nav,
.logo-net,
.page-meta,
.content-actions,
.social,
.social-links,
.no-print {
    display: none !important;
}
